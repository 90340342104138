import React, { useEffect, useState } from "react";

import { Button } from "../../ui/button";
import Input from "../../Tags/Input";
import { List, Search } from "lucide-react";
import NoData from "../../NoData";
import { MagnifyingGlassIcon } from "@radix-ui/react-icons";

export default function Attributes({ closeModal, attributes = [], getAttributes, activeAttributes = [] }) {
  const [selectedAttributes, setSelectedAttributes] = useState(activeAttributes);
  const handleCheckboxChange = (attr) => {
    setSelectedAttributes((prev) => {
      const isAlreadySelected = prev.find((a) => a.id === attr.id);
      if (isAlreadySelected) {
        // Remove it
        return prev.filter((a) => a.id !== attr.id);
      } else {
        // Add it
        return [...prev, attr];
      }
    });
  };

  const [searchQuery, setSearchQuery] = useState("");

  const filterAttributesBySearchTerm = (searchTerm) => {
    const term = searchTerm.toLowerCase();

    return attributes
      .map((group) => {
        const categoryMatch = group.categoryName.toLowerCase().includes(term);
        const matchedAttributes = group.attributes.filter((attr) =>
          attr.display_name.toLowerCase().includes(term)
        );

        // Keep if category name matches OR at least one attribute matches
        if (categoryMatch || matchedAttributes.length > 0) {
          return {
            categoryName: group.categoryName,
            attributes: categoryMatch ? group.attributes : matchedAttributes,
          };
        }

        return null;
      })
      .filter(Boolean); // Remove nulls
  };

  return (
    <div>
      <div className="flex items-center space-x-4">
        <div>
          <h2 className="text-base font-semibold leading-7 ">Select attributes</h2>
        </div>
      </div>
      <div className="my-4">
        <Input
          onChange={(e) => {
            const input = e.target.value;
            if (input === " " && searchQuery === "") return;
            const cleaned = input.replace(/\s+/g, " ").replace(/^\s+|\s+$/g, "");
            setSearchQuery(cleaned);
          }}
          placeholder="Search"
          icon={<Search width={14} height={14} className="text-gray-400" />}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck={false}
        />
      </div>

      <div className="h-[500px] overflow-y-scroll overflow-x-hidden custom-scrollable-dropdown">
        {attributes &&
          attributes.length > 0 &&
          filterAttributesBySearchTerm(searchQuery).map((item) => (
            <div className="block mb-5">
              <h2 className="text-[16px] font-bold text-black mb-2">{item?.categoryName}</h2>
              <div className="-mx-2">
                {item &&
                  item.attributes &&
                  item.attributes.length > 0 &&
                  item.attributes.map((attr) => {
                    const isChecked = selectedAttributes.some((a) => a.id === attr.id);

                    return (
                      <div className="inline-flex items-start space-x-2 w-[33.33%] px-4 my-2 font-bold text-gray-500 text-sm">
                        <input
                          type="checkbox"
                          className="rounded dark:checked:bg-black dark:checked:border-white"
                          checked={isChecked}
                          onChange={() => handleCheckboxChange(attr)}
                        />{" "}
                        <span className="-mt-0.5">{attr?.display_name}</span>
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
        {filterAttributesBySearchTerm(searchQuery).length === 0 && (
          <NoData
            icon={
              <div className="flex justify-center">
                <List width={48} height={48} />
              </div>
            }
            heading={"No Attribute found!"}
            description={"Please modify search"}
            filterMode={true}
          />
        )}
      </div>
      <div className="border-t pt-5 ">
        <Button
          className="px-8"
          onClick={() => {
            getAttributes(selectedAttributes);
            closeModal();
          }}
        >
          Add {selectedAttributes.length > 0 && `(${selectedAttributes.length})`}
        </Button>
      </div>
    </div>
  );
}
