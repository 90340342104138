import React, { useState, useEffect } from "react";
import Input from "../../Tags/Input";

const StringInput = ({ currentValue = "", onChange }) => {
  const [value, setValue] = useState(currentValue);

  const handleChange = (e) => {
    const input = e.target.value;
    setValue(input);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  return (
    <div className="flex mt-3">
      <Input type="text" value={value} onChange={handleChange} placeholder="Enter value" />
    </div>
  );
};

export default StringInput;
