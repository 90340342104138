import { Range } from "react-range";
import { useState } from "react";
import { useEffect } from "react";

const MIN = 0;
const MAX = 100;
const STEP = 1;
const MIN_GAP = 2;

const TierSlider = ({ defaultValue, getConfig }) => {
  const extractValuesFromConfig = (config) => {
    if (config && config["Not a fit"] && config["Fair"] && config["Good"]) {
      return [config["Not a fit"][1], config["Fair"][1], config["Good"][1]];
    }
    return [49, 64, 79]; // fallback default
  };

  const [values, setValues] = useState(extractValuesFromConfig(defaultValue));

  const isValid = (arr) => {
    return arr[0] >= 1 && arr[2] <= 98 && arr[1] - arr[0] >= MIN_GAP && arr[2] - arr[1] >= MIN_GAP;
  };

  const handleChange = (newValues) => {
    if (isValid(newValues)) {
      setValues(newValues);
    }
  };

  const scoringTierConfig = {
    "Not a fit": [0, values[0]],
    Fair: [values[0] + 1, values[1]],
    Good: [values[1] + 1, values[2]],
    Excellent: [values[2] + 1, 100],
  };

  const tierLabels = [
    { label: "Not a fit", color: "#717680", min: 0, max: values[0] },
    { label: "Fair", color: "#DC6803", min: values[0] + 1, max: values[1] },
    { label: "Good", color: "#0086C9", min: values[1] + 1, max: values[2] },
    { label: "Excellent", color: "#079455", min: values[2] + 1, max: 100 },
  ];

  useEffect(() => {
    getConfig(JSON.parse(JSON.stringify(scoringTierConfig, null, 2)));
  }, []);

  return (
    <div className="w-full py-10">
      <Range
        values={values}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={handleChange}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className="h-10 w-full rounded-lg flex relative"
            style={{
              background: `linear-gradient(
                to right,
                #D5D7DA 0% ${values[0]}%,
                #FEC84B ${values[0]}% ${values[1]}%,
                #36BFFA ${values[1]}% ${values[2]}%,
                #47CD89 ${values[2]}% 100%
              )`,
            }}
          >
            {children}
          </div>
        )}
        onFinalChange={() => {
          getConfig(JSON.parse(JSON.stringify(scoringTierConfig, null, 2)));
        }}
        renderThumb={({ index, props }) => (
          <div {...props} className="w-4 h-9 bg-white border border-gray-300 rounded shadow relative">
            <div className="h-11 w-[1px] bg-white absolute -top-1 left-1.5"></div>
            <svg
              width="6"
              height="20"
              viewBox="0 0 6 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="absolute m-auto left-1 top-1.5"
            >
              <path d="M1 1L1 19" stroke="#A4A7AE" stroke-linecap="round" />
              <path d="M5 1L5 19" stroke="#A4A7AE" stroke-linecap="round" />
            </svg>

            <span className="text-xs text-gray-600 absolute -top-6 left-1/2 transform -translate-x-1/2">
              {values[index]}%
            </span>
          </div>
        )}
      />

      <div className="relative w-full mt-3 h-10">
        {tierLabels.map((tier, i) => {
          const mid = (tier.min + tier.max) / 2;
          return (
            <div
              key={i}
              className="absolute whitespace-pre flex items-center justify-center px-2 py-1.5 rounded-full text-white text-sm font-medium"
              style={{
                left: `${mid}%`,
                transform: "translateX(-50%)",
                backgroundColor: tier.color,
              }}
            >
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mr-1"
              >
                <path
                  d="M18.8332 9.99984H15.4998M5.49984 9.99984H2.1665M10.4998 4.99984V1.6665M10.4998 18.3332V14.9998M17.1665 9.99984C17.1665 13.6817 14.1817 16.6665 10.4998 16.6665C6.81794 16.6665 3.83317 13.6817 3.83317 9.99984C3.83317 6.31794 6.81794 3.33317 10.4998 3.33317C14.1817 3.33317 17.1665 6.31794 17.1665 9.99984ZM12.9998 9.99984C12.9998 11.3805 11.8805 12.4998 10.4998 12.4998C9.11913 12.4998 7.99984 11.3805 7.99984 9.99984C7.99984 8.61913 9.11913 7.49984 10.4998 7.49984C11.8805 7.49984 12.9998 8.61913 12.9998 9.99984Z"
                  stroke="white"
                  stroke-width="1.875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <span>{tier.label}</span>
              <span className="ml-1">
                ({scoringTierConfig[tier.label][0]} to {scoringTierConfig[tier.label][1]})
              </span>
              <div
                className="absolute left-1/2 -top-1 w-2 h-2 rotate-45"
                style={{ backgroundColor: tier.color }}
              />
            </div>
          );
        })}
      </div>

      {/* <pre className="mt-6 text-sm bg-gray-100 p-4 rounded">{JSON.stringify(scoringTierConfig, null, 2)}</pre> */}
    </div>
  );
};

export default TierSlider;
