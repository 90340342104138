import React, { useState } from "react";
import { Range } from "react-range";

const MIN = -10;
const MAX = 10;
const STEP = 1;

const SingleRangeSlider = ({ defaultValue = 0, onChange }) => {
  const [value, setValue] = useState([defaultValue]);

  const formatDisplayValue = (val) => {
    if (val > 0) return `+${val}`;
    if (val < 0) return `${val}`;
    return "0";
  };

  const percentage = (val) => ((val - MIN) / (MAX - MIN)) * 100;

  const trackFillStyle = () => {
    const left = value[0] >= 0 ? percentage(0) : percentage(value[0]);
    const width = Math.abs(percentage(value[0]) - percentage(0));
    return { left: `${left}%`, width: `${width}%` };
  };

  return (
    <div className="w-full relative">
      <Range
        values={value}
        step={STEP}
        min={MIN}
        max={MAX}
        onChange={(val) => {
          // Clamping handled automatically by Range + min/max props
          setValue(val);
          onChange(val);
        }}
        renderTrack={({ props, children }) => (
          <div {...props} className="h-2 w-full rounded-full bg-gray-200 relative">
            {/* Filled track from 0 to selected value */}
            <div className="absolute h-full rounded-full bg-purple-500" style={trackFillStyle()} />

            {/* Breakpoints/dots */}
            {[...Array(MAX - MIN + 1)].map((_, i) => {
              const pos = MIN + i;
              return (
                <div
                  key={pos}
                  className="absolute top-1/2 w-1 h-1 bg-white border border-gray-400 rounded-full transform -translate-y-1/2"
                  style={{ left: `${percentage(pos)}%` }}
                />
              );
            })}

            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className="w-5 h-5 rounded-full bg-white border-2 border-purple-500 shadow relative"
          >
            <div className="absolute top-7 left-1/2 transform -translate-x-1/2 px-2 py-1 bg-background shadow rounded text-sm font-semibold">
              {formatDisplayValue(value[0])}
            </div>
          </div>
        )}
      />

      <div className="flex justify-between mt-4 text-sm font-medium text-gray-700">
        <span>-10</span>
        <span>0</span>
        <span>+10</span>
      </div>
    </div>
  );
};

export default SingleRangeSlider;
