import { date } from "yup";
import { DELETE, GET, PATCH, POST, PUT } from "../../axios/apiService";

export const getICPs = async ({ query, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps${query ? `?${query}` : ""}`;
  return GET(url, { withCredentials: true, ...options });
};

export const getICPById = async ({ id, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/${id}`;
  return GET(url, { withCredentials: true, ...options });
};

export const getAttributes = async ({ query, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/attributes`;
  return GET(url, { withCredentials: true, ...options });
};

export const getAttributeById = async ({ id, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/attributes/${id}`;
  return GET(url, { withCredentials: true, ...options });
};

export const createICP = async ({ data, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps`;
  return POST(url, data, options);
};

export const updateICP = async ({ data, id, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/${id}`;
  return PUT(url, data, { withCredentials: true, ...options });
};

export const deleteIcp = async ({ id, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/${id}`;
  return DELETE(url, options);
};

export const patchICP = async ({ data, id, options }) => {
  const url = `${process.env.REACT_APP_API_FITMENT_BASE_URL}/api/v1/icps/${id}`;
  return PATCH(url, data, { withCredentials: true, ...options });
};
