import React, { useEffect, useState } from "react";
import NavAnchor from "../../Tags/NavAnchor";
import Image from "../../Tags/Image";
import { ChevronLeftCircle, ChevronRightCircle } from "lucide-react";
import { useCaptureEvent } from "../../../hooks/useCaptureEvent";
export default function LeftNav() {
  const menus = [
    {
      name: "Workspace",
      href: "/settings/general",
      icon: "/images/icons/untitledui/workspace.svg",
      activePaths: ["/settings/general"],
    },
    {
      name: "Members",
      href: "/settings/members",
      icon: "/images/icons/untitledui/user.svg",
      activePaths: ["/settings/members"],
    },
    {
      name: "Groups",
      href: "/settings/groups",
      icon: "/images/icons/untitledui/group.svg",
      activePaths: ["/settings/groups"],
    },
    {
      name: "Integrations",
      href: "/settings/integrations?tab=integrations&category=View all",
      icon: "/images/icons/untitledui/toggle.svg",
      activePaths: ["/settings/integrations"],
    },
    {
      name: "Security",
      href: "/settings/security",
      icon: "/images/icons/untitledui/safe.svg",
      activePaths: ["/settings/security"],
    },
    {
      name: "API Keys",
      href: "/settings/api-keys",
      icon: "/images/icons/untitledui/key.svg",
      activePaths: ["/settings/api-keys"],
    },
    {
      name: "Credit Log",
      href: "/settings/credit-consumption-log",
      icon: "/images/icons/untitledui/log.svg",
      activePaths: ["/settings/credit-consumption-log"],
    },
  ];
  const menusIntelligence = [
    {
      name: "Scoring",
      href: "/intelligence/scoring",
      icon: "/images/icons/untitledui/scoring.svg",
      activePaths: ["/intelligence/scoring"],
    },
  ];
  const captureEvent = useCaptureEvent();
  const [sidebarMini, setSidebarMini] = useState(() => {
    // Initialize state from local storage or fallback to false
    const savedState = localStorage.getItem("sidebarMini");
    return savedState !== null ? JSON.parse(savedState) : false;
  });

  const toggleSidebar = () => {
    setSidebarMini((prevSidebarMini) => !prevSidebarMini);
  };

  // Update local storage whenever sidebarMini changes
  useEffect(() => {
    localStorage.setItem("sidebarMini", JSON.stringify(sidebarMini));
  }, [sidebarMini]);

  return (
    <div
      className={`relative p-4  transition-[width] ease-in-out ${
        sidebarMini ? "w-[78px] sidebar__mini" : "w-[260px] sidebar__full"
      }`}
    >
      <div className="flex items-center w-full justify-between mb-4 sidebar__heading__wrapper">
        <h3 className="font-medium text-base text-text-900 sidebar__heading">Settings</h3>
        <div className="text-brand-600 font-normal bg-brand-100 rounded-full cursor-pointer">
          {sidebarMini ? (
            <ChevronRightCircle width={18} height={18} onClick={toggleSidebar} />
          ) : (
            <ChevronLeftCircle width={18} height={18} onClick={toggleSidebar} />
          )}
        </div>
      </div>
      {menus.map((item) => (
        <NavAnchor
          key={item.name}
          to={`${item.href}`}
          activeClassName={
            "sidebar__item__menu text-base bg-secondary text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary"
          }
          className={`sidebar__item__menu text-base text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary`}
          activePaths={item.activePaths}
          onClick={() => {
            captureEvent("SET_SideNavBar_" + item.name + "Btn");
          }}
        >
          {item.icon && (
            <Image src={item.icon} alt="" width="20" className="mr-3 sidebar__item__menu__icon" />
          )}
          <div className="flex-auto sidebar__item__menu__text">
            <span className="block font-semibold ">{item.name}</span>
          </div>
        </NavAnchor>
      ))}
      <h3 className="font-medium text-base text-text-900 sidebar__heading mt-5 mb-3">Intelligence</h3>
      {menusIntelligence.map((item) => (
        <NavAnchor
          key={item.name}
          to={`${item.href}`}
          activeClassName={
            "sidebar__item__menu text-base bg-secondary text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary"
          }
          className={`sidebar__item__menu text-base text-text-700 my-1 group relative flex items-center rounded-lg py-2 px-3 leading-6 hover:bg-secondary`}
          activePaths={item.activePaths}
          onClick={() => {
            captureEvent("SET_SideNavBar_" + item.name + "Btn");
            if (item.name === "Scoring") {
              window.dispatchEvent(new Event("clearScoringRouteState"));
            }
          }}
        >
          {item.icon && (
            <Image src={item.icon} alt="" width="20" className="mr-3 sidebar__item__menu__icon" />
          )}
          <div className="flex-auto sidebar__item__menu__text">
            <span className="block font-semibold ">{item.name}</span>
          </div>
        </NavAnchor>
      ))}
    </div>
  );
}
