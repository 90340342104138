import React from "react";
import Layout from "../../Layout";
import Scoring from "../../containers/Scoring";

export default function ScoringPage() {
  return (
    <Layout>
     <Scoring />
    </Layout>
  );
}
