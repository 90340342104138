import React, { useEffect, useMemo, useRef, useState } from "react";
import SingleRangeSlider from "./SingleRangeSlider";
import { Button } from "../../ui/button";
import { Check, ChevronDown, Trash2 } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "../../ui/popover";
import { cn } from "../../../lib/utils";
import DualNumberInput from "./DualNumberInput";
import StringInput from "./StringInput";
import DynamicDateConditionInput from "./DynamicDateConditionInput";
import { isEqual } from "lodash";

import { useVirtualizer } from "@tanstack/react-virtual";
import Input from "../../Tags/Input";

export default function ParameterCard({
  defaultValue = {},
  currentAttribute = {},
  slider = {},
  sliderLength,
  removeSlider,
  getCardData,
}) {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [currentOperator, setCurrentOperator] = useState({});
  const [parameter, setParameter] = useState({ weight: 0 });

  const removeUndefinedKeys = (obj) => {
    return Object.fromEntries(Object.entries(obj).filter(([_, value]) => value !== undefined));
  };
  const updateParameter = (newValues) => {
    setParameter((prev) => {
      const updated = { ...prev };

      Object.entries(newValues).forEach(([key, value]) => {
        if (value === undefined) {
          delete updated[key]; // remove the key if undefined
        } else {
          updated[key] = value; // otherwise, update it
        }
      });

      return updated;
    });
  };
  const removeParameterKeys = (keysToRemove) => {
    setParameter((prev) => {
      const updated = { ...prev };
      keysToRemove.forEach((key) => {
        delete updated[key];
      });
      return updated;
    });
  };

  useEffect(() => {
    if (currentOperator?.value) {
      updateParameter({ operator: currentOperator?.value });
    }
  }, [currentOperator]);

  const [specificValueObj, setSpecificValueObj] = useState({});
  const [specificValue, setSpecificValue] = useState("");
  useEffect(() => {
    if (specificValue) {
      updateParameter({ specific_attribute: specificValue });
    } else {
      removeParameterKeys(["specific_attribute"]);
    }
  }, [specificValue]);

  const [listValues, setListValues] = useState([]);
  const updateListValues = (id, obj) => {
    if (!obj?.id) return;

    setListValues((prev) => {
      const exists = prev.some((el) => el.id === id);
      if (exists) {
        // Remove it
        return prev.filter((el) => el.id !== id);
      } else {
        // Add it
        return [...prev, { id, ...obj }];
      }
    });
  };

  useEffect(() => {
    console.log("listValues", listValues);
    if (listValues && listValues.length > 0) {
      const ids = listValues.map((el) => el.id);
      console.log("ids", ids);
      updateParameter({ values: ids });
    } else {
      removeParameterKeys(["values"]);
    }
  }, [listValues]);

  const printValue = (parameter, currentOperator) => {
    if (!currentOperator?.name) {
      return;
    }
    let string = "";
    if (parameter.value || parameter.value == 0) {
      string = `: ${parameter.value}`;
    } else if (parameter.min_value || parameter.min_value == 0) {
      string = `: ${parameter.min_value}  ${parameter.max_value ? "to " + parameter.max_value : ""}`;
    } else if (parameter.values) {
      const values = currentOperator?.type === "Date" ? parameter.values : listValues.map((el) => el.label);
      string = `: ${values}`;
    } else {
      string = "";
    }

    if (currentAttribute?.dimensionality === 2 && specificValueObj?.value) {
      string += `, ${specificValueObj?.value}`;
    }
    return string.replace(/,\s*/g, ", ");
  };

  const isOnlyIdOrIdAndOperator = (parameter) => {
    const keys = Object.keys(parameter);
    if (keys.length === 1 && keys.includes("id")) return true;
    if (keys.length === 2 && keys.includes("id") && keys.includes("operator")) return true;
    return false;
  };

  useEffect(() => {
    const currentOperatorObj =
      currentAttribute.operators &&
      currentAttribute.operators.length > 0 &&
      currentAttribute.operators.find((el) => el.value === defaultValue?.operator);
    const firstOperator =
      currentAttribute.operators && currentAttribute.operators.length > 0 && currentAttribute.operators[0];
    setCurrentOperator(currentOperatorObj || firstOperator);
    setParameter(defaultValue);
    setSpecificValue(defaultValue?.specific_attribute);
    // console.log("defaultValue", defaultValue);
    if (
      currentAttribute.values &&
      currentAttribute.values.values &&
      currentAttribute.values.values.length > 0
    ) {
      const objArr = currentAttribute.values.values.filter((value) => {
        if (
          defaultValue &&
          defaultValue.values &&
          Array.isArray(defaultValue.values) &&
          defaultValue.values.length > 0
        ) {
          return defaultValue.values.find((el) => el === value.id);
        }
      });

      setListValues(objArr.map((el) => ({ ...el, label: el?.value })));
      const objSpecific = currentAttribute.values.values.find(
        (el) => el.id === defaultValue?.specific_attribute
      );
      setSpecificValueObj(objSpecific);
    } else {
      setListValues([]);
    }
  }, [currentAttribute, slider]);

  // useEffect(() => {
  //   console.log("parameter", parameter);
  //   getCardData(parameter);
  // }, [parameter]);
  const prevParameterRef = useRef();
  const debounceRef = useRef();
  useEffect(() => {
    console.log("defaultValue", { ...defaultValue, name: currentAttribute?.display_name });
    if (isEqual(parameter, prevParameterRef.current)) return;

    // Clear previous debounce timer
    clearTimeout(debounceRef.current);

    // Start new debounce
    debounceRef.current = setTimeout(() => {
      prevParameterRef.current = parameter;
      console.log("parameter (debounced)", parameter);
      getCardData(parameter);
      if (parameter && isOnlyIdOrIdAndOperator(parameter)) {
        setOpen(true);
      }
    }, 1500);

    // Cleanup on unmount
    return () => clearTimeout(debounceRef.current);
  }, [parameter]);

  const [readyToRender, setReadyToRender] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredValues = useMemo(() => {
    if (!searchTerm) return currentAttribute?.values?.values || [];
    return currentAttribute?.values?.values?.filter((item) =>
      item.value?.toLowerCase().includes(searchTerm?.toLowerCase())
    );
  }, [searchTerm, currentAttribute]);

  const parentRef = useRef(null);
  const itemCount = readyToRender ? filteredValues.length : 0;

  const rowVirtualizer = useVirtualizer({
    count: searchTerm ? filteredValues.length : itemCount,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 40,
    overscan: 5,
  });

  useEffect(() => {
    if (open) {
      requestAnimationFrame(() => {
        setReadyToRender(true);
      });
    } else {
      setReadyToRender(false);
    }
  }, [open]);

  const ITEM_HEIGHT = 40;
  const MAX_HEIGHT = 288;

  const isScrollable = filteredValues.length * ITEM_HEIGHT > MAX_HEIGHT;
  const containerHeight = isScrollable ? MAX_HEIGHT : filteredValues.length * ITEM_HEIGHT;

  return (
    <div
      key={slider.id}
      className={`p-4 ${sliderLength !== 1 && "pr-16"} text-sm w-full rounded-[--radius] border border-input bg-gray-50 text-text-900 mt-4 relative`}
    >
      {/* <span className="font-bold inline-flex h-11 px-[14px] py-[10px] text-sm  rounded-[--radius] border border-input bg-background text-text-900">
            {current?.name}
          </span> */}

      <div className="mb-4">
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild className="z-10">
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className="justify-between h-min whitespace-normal text-left py-2 rounded-[8px] font-semibold pr-2.5"
            >
              {currentOperator && currentOperator?.name ? currentOperator?.name : "--Select--"}
              {printValue(parameter, currentOperator)}
              {/* <ChevronDown className="ml-2 h-4 w-4 shrink-0" /> */}
            </Button>
          </PopoverTrigger>
          <PopoverContent className="bg-white p-4 w-[400px]" align="start">
            <Popover open={open2} onOpenChange={setOpen2}>
              <PopoverTrigger asChild className="z-10">
                <Button
                  variant="outline"
                  role="combobox"
                  aria-expanded={open2}
                  className="justify-between h-11 w-full rounded-[8px] font-semibold pr-2.5"
                >
                  {currentOperator && currentOperator?.name ? currentOperator?.name : "--Select--"}
                  <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
                </Button>
              </PopoverTrigger>
              <PopoverContent className="w-[365px] p-0" align="end">
                <Command>
                  <div className="p-2">
                    <div className="border  rounded-[8px] border-b-0 overflow-hidden">
                      <CommandInput placeholder="Search" />
                    </div>
                  </div>
                  <CommandList>
                    <CommandEmpty>No List found.</CommandEmpty>
                    <CommandGroup>
                      {currentAttribute.operators &&
                        currentAttribute.operators.length > 0 &&
                        currentAttribute.operators.map((item) => {
                          const newItem = {
                            ...item,
                            value: item.value,
                            label: item.name,
                          };

                          return (
                            <CommandItem
                              key={newItem.value}
                              value={newItem.label}
                              onSelect={() => {
                                if (newItem.value === currentOperator?.value) {
                                  setCurrentOperator({});

                                  setOpen2(false);
                                } else {
                                  setCurrentOperator(newItem);

                                  setOpen2(false);
                                }
                                if (newItem?.label?.toLowerCase().includes("include")) {
                                  removeParameterKeys(["value"]);
                                }
                                if (newItem?.label?.toLowerCase().includes("contain")) {
                                  removeParameterKeys(["values"]);
                                  setListValues([]);
                                }
                              }}
                              className="min-h-[40px]"
                            >
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  currentOperator?.value === newItem.value ? "opacity-100" : "opacity-0"
                                )}
                              />
                              {newItem.label}
                            </CommandItem>
                          );
                        })}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </Popover>
            {currentOperator &&
              currentOperator.name &&
              (currentOperator.type === "Numeric" ||
                currentOperator.type === "NumericAndString" ||
                currentOperator.type === "StringAndNumeric") && (
                <DualNumberInput
                  currentValue={
                    currentOperator.value_type === "range" ? parameter?.min_value : parameter?.value
                  }
                  currentMaxValue={parameter?.max_value}
                  showMaxInput={currentOperator.value_type === "range" ? true : false}
                  onChange={(data) => {
                    console.log("num data", data);
                    if (data.value) {
                      removeParameterKeys(["min_value", "max_value"]);
                    } else {
                      removeParameterKeys(["value"]);
                    }
                    updateParameter(data);
                  }}
                  validation={{
                    min: currentAttribute?.values?.min_value,
                    max: currentAttribute?.values?.max_value,
                  }}
                />
              )}
            {currentOperator &&
              currentOperator.name &&
              currentOperator.type === "String" &&
              currentOperator?.name?.toLowerCase()?.includes("contain") && (
                <StringInput
                  onChange={(data) => {
                    console.log(data);
                    updateParameter({ value: data });
                  }}
                  currentValue={parameter?.value}
                />
              )}
            {currentOperator &&
              currentOperator.name &&
              currentAttribute.operators &&
              currentAttribute.operators.length > 0 &&
              currentOperator.type === "Date" && (
                <DynamicDateConditionInput
                  date1={
                    parameter.values && parameter.values.length > 0 ? parameter.values[0] : parameter.value
                  }
                  date2={parameter.values && parameter.values.length > 0 && parameter.values[1]}
                  options={currentAttribute.operators}
                  selected={currentOperator}
                  onChange={(data) => {
                    console.log("date data", data);
                    let obj = data;
                    if (Array.isArray(data.value) && data.value && data.value.length > 0) {
                      obj.values = obj.value;
                      delete obj.value;
                      removeParameterKeys(["value"]);
                    } else {
                      removeParameterKeys(["values"]);
                    }
                    updateParameter(obj);
                  }}
                />
              )}

            {currentAttribute.values &&
              currentAttribute.values.values &&
              currentAttribute.values.values.length > 0 &&
              !currentOperator?.name?.toLowerCase()?.includes("contain") && (
                <Command>
                  <div className="py-2">
                    <div className="border rounded-[8px] border-b-0 overflow-hidden">
                      <Input
                        defaultValue={searchTerm}
                        onChange={(e) => {
                          const input = e.target.value;
                          if (input === " " && searchTerm === "") return;
                          const cleaned = input.replace(/\s+/g, " ").replace(/^\s+|\s+$/g, "");
                          setSearchTerm(cleaned);
                        }}
                        placeholder="Search"
                        autoCorrect="off"
                        autoCapitalize="off"
                        spellCheck={false}
                      />
                    </div>
                  </div>
                  <CommandList>
                    <CommandEmpty>No List found.</CommandEmpty>
                    <CommandGroup>
                      {readyToRender && (
                        <div
                          ref={parentRef}
                          className="relative overflow-y-auto max-h-72"
                          style={{ height: `${containerHeight}px` }}
                        >
                          <div
                            style={{
                              height: `${rowVirtualizer.getTotalSize()}px`,
                              position: "relative",
                            }}
                          >
                            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
                              const item = filteredValues[virtualRow.index];
                              const newItem = {
                                ...item,
                                value: item.id,
                                label: item.value,
                              };

                              return (
                                <div
                                  key={newItem.value}
                                  className="absolute top-0 left-0 w-full"
                                  style={{ transform: `translateY(${virtualRow.start}px)` }}
                                >
                                  <CommandItem
                                    value={newItem.label}
                                    onSelect={() => {
                                      if (currentAttribute?.dimensionality === 2) {
                                        if (specificValue === item.id) {
                                          setSpecificValue("");
                                          setSpecificValueObj({});
                                        } else {
                                          setSpecificValue(item.id);
                                          setSpecificValueObj(item);
                                        }
                                      } else {
                                        updateListValues(item.id, newItem);
                                      }
                                    }}
                                    className="min-h-[40px]"
                                  >
                                    {currentAttribute?.dimensionality === 2 ? (
                                      <Check
                                        className={cn(
                                          "mr-2 h-4 w-4",
                                          specificValue === newItem.id ? "opacity-100" : "opacity-0"
                                        )}
                                      />
                                    ) : (
                                      <input
                                        type="checkbox"
                                        className="rounded mr-2"
                                        checked={listValues.find((el) => el.id === newItem.id) ? true : false}
                                      />
                                    )}

                                    {newItem.label}
                                  </CommandItem>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      )}
                    </CommandGroup>
                  </CommandList>
                </Command>
              )}

            <Button
              className="mt-3"
              onClick={() => {
                setOpen(false);
                setOpen2(false);
              }}
            >
              Done
            </Button>
          </PopoverContent>
        </Popover>
      </div>

      <SingleRangeSlider
        onChange={(value) => {
          updateParameter({ weight: value[0] });
        }}
        defaultValue={defaultValue?.weight}
      />
      {sliderLength > 1 && (
        <Button
          className="py-3 px-2 h-3 absolute right-2 top-2"
          variant="outline"
          onClick={() => removeSlider(slider.id)}
        >
          <Trash2 width={16} height={16} />
        </Button>
      )}
    </div>
  );
}
