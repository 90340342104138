import React, { useEffect, useState } from "react";
import { Button } from "../../ui/button";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { getAttributeById } from "../../../apis/workspace/general";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";

import ParameterCard from "./ParameterCard";
import { Plus, Trash2 } from "lucide-react";

export default function AttributeCard({ attr, removeAttribute, getAttrPayload }) {
  const [currentAttribute, setCurrentAttribute] = useState({});
  const { activeWorkspace } = useRoleCheck();
  // get attribute data by id
  const getAttributeByIdFn = useMutation("get_attributes", getAttributeById, {
    onSuccess(data, variables, context) {
      setCurrentAttribute(data.data);
    },
  });

  const handleGetAttributeById = (id) => {
    if (getAttributeByIdFn.isLoading) {
      return;
    }
    getAttributeByIdFn.mutate({
      id: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };
  useEffect(() => {
    if (activeWorkspace?.id && attr.id) {
      handleGetAttributeById(attr.id);
    }
  }, [activeWorkspace?.id, attr?.id]);

  const [sliders, setSliders] = useState([{ id: uuidv4() }]);

  const addSlider = () => {
    setSliders((prev) => [...prev, { id: uuidv4() }]);
  };

  const removeSlider = (id) => {
    setSliders((prev) => prev.filter((slider) => slider.id !== id));
  };
  const updateSliderById = (sliderId, updatedSliderObject) => {
    // setSliders((prev) =>
    //   prev.map((slider) => (slider.id === sliderId ? { ...slider, ...updatedSliderObject } : slider))
    // );
    setSliders((prev) =>
      prev.map((slider) => {
        if (slider.id !== sliderId) return slider;

        const cleanedSlider = { ...slider };

        if (Array.isArray(updatedSliderObject.values)) {
          delete cleanedSlider.value;
        }

        if (updatedSliderObject.value !== undefined) {
          delete cleanedSlider.values;
        }

        const finalObj = {
          // ...cleanedSlider,
          id: slider.id,
          ...updatedSliderObject,
        };
        return finalObj;
      })
    );
  };

  useEffect(() => {
    console.log("sliders", sliders);
    getAttrPayload({
      id: currentAttribute.id,
      name: currentAttribute.attribute_name,
      display_name: currentAttribute.display_name,
      key: currentAttribute?.data_path,
      parameters: sliders,
    });
  }, [sliders]);

  useEffect(() => {
    if (attr?.parameters && attr?.parameters.length > 0) {
      const mappedParameters = attr?.parameters?.map((el) => {
        return { id: uuidv4(), ...el };
      });
      console.log("mappedParameters", { ...mappedParameters, name: currentAttribute?.display_name });
      setSliders(mappedParameters);
    }
  }, [attr?.parameters, currentAttribute]);

  return (
    <div className="px-[14px] py-[15px] text-sm w-full rounded-[--radius] border border-input bg-background text-text-900 mt-2">
      <div className="flex items-center justify-between">
        <p className="font-bold">{attr.display_name}</p>
        <Button
          className="py-4 px-3 h-4"
          variant="danger-outline"
          onClick={() => {
            removeAttribute(attr?.id);
          }}
        >
          <Trash2 width={16} height={16} />
        </Button>
      </div>

      {sliders.map((slider) => (
        <ParameterCard
          currentAttribute={currentAttribute}
          slider={slider}
          sliderLength={sliders.length}
          removeSlider={(id) => {
            removeSlider(id);
          }}
          getCardData={(data) => {
            console.log("getCardData", { ...data, name: currentAttribute?.display_name });
            updateSliderById(slider.id, data);
          }}
          defaultValue={slider}
        />
      ))}
      <Button className="mt-3" variant="outline" size="sm" onClick={addSlider}>
        <Plus className="mr-1" size={16} />
        Add Parameter
      </Button>
    </div>
  );
}
