import React, { useEffect, useState } from "react";
import Text from "../../Tags/Text";
import { Button } from "../../ui/button";
import Input from "../../Tags/Input";
import TierSlider from "./Range";
import Image from "../../Tags/Image";
import { PlusIcon } from "lucide-react";
import AttributeCard from "./AttributeCard";
import GenericModal from "../../GenericModal";
import Attributes from "./Attributes";
import { useFormik } from "formik";
import * as yup from "yup";
import useRoleCheck from "../../../hooks/useRoleCheck";
import useModal from "../../GenericModal/useModal";
import { createICP, getAttributes, getICPById, updateICP } from "../../../apis/workspace/general";
import { useMutation } from "react-query";

export default function CreateScore({
  editMode = false,
  currentIcp: currentIcpData,
  onCreated,
  onUpdated,
  onCancel,
}) {
  const [currentIcp, setCurrentIcp] = useState(currentIcpData);
  const { activeWorkspace } = useRoleCheck();
  // add form
  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    description: yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema,
    onSubmit: (values) => {
      // console.log("Submitted values:", values);
    },
  });

  // get attributes api
  const [attributes, setAttributes] = useState([]);
  const { isOpen, openModal, closeModal } = useModal();
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const getAttributesFn = useMutation("get_attributes", getAttributes, {
    onSuccess(data, variables, context) {
      setAttributes(data.data);
    },
  });

  const handleGetAttributes = () => {
    if (getAttributesFn.isLoading) {
      return;
    }
    getAttributesFn.mutate({
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (activeWorkspace?.id) {
      handleGetAttributes();
    }
  }, [activeWorkspace?.id]);

  useEffect(() => {
    console.log("selectedAttributes", selectedAttributes);
  }, [selectedAttributes]);

  const [payloadTierConfig, setPayloadTierConfig] = useState({});
  const [payloadAttributes, setPayloadAttributes] = useState([]);

  const removePayloadAttr = (id) => {
    setPayloadAttributes((prev) => prev.filter((attr) => attr.id !== id));
  };
  const updatePayloadAttributes = (attrId, obj) => {
    if (!obj?.id) {
      return;
    }
    setPayloadAttributes((prev) => {
      const exists = prev.some((attr) => attr.id === attrId);
      if (exists) {
        return prev.map((attr) => (attr.id === attrId ? { ...attr, ...obj } : attr));
      } else {
        return [...prev, { id: attrId, ...obj }];
      }
    });
  };
  useEffect(() => {
    console.log("payloadAttributes", payloadAttributes);
  }, [payloadAttributes]);

  const createICPFn = useMutation("create_icp", createICP, {
    onSuccess(data, variables, context) {
      if (onCreated) {
        onCreated();
      }
    },
    onError(error) {
      console.log(error);
    },
  });

  const clickSave = () => {
    const finalPayload = {
      name: formik.values.name,
      description: formik.values.description,
      attributes: payloadAttributes,
      scoring_tier_config: payloadTierConfig,
      is_active: true,
    };
    console.log("finalPayload", finalPayload);

    if (createICPFn.isLoading) {
      return;
    }

    createICPFn.mutate({
      data: finalPayload,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    if (editMode && currentIcp?.id) {
      formik.setValues({ name: currentIcp?.name, description: currentIcp?.description });
      setSelectedAttributes(currentIcp.attributes);
    }
  }, [editMode && currentIcp?.id]);

  // update icp by id
  const updateICPFn = useMutation("get_icp_by_id", updateICP, {
    onSuccess(data, variables, context) {
      if (onUpdated) {
        onUpdated();
      }
      setSelectedAttributes([]);
      formik.resetForm();
      setPayloadAttributes([]);
    },
  });
  const clickUpdate = () => {
    const finalPayload = {
      name: formik.values.name,
      description: formik.values.description,
      attributes: payloadAttributes,
      scoring_tier_config: payloadTierConfig,
      is_active: true,
    };
    console.log("finalPayload", finalPayload);

    if (updateICPFn.isLoading) {
      return;
    }

    updateICPFn.mutate({
      id: currentIcp?.id || currentIcpData?.id,
      data: finalPayload,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // state clear
  useEffect(() => {
    return () => {
      setCurrentIcp({});
      setAttributes([]);
      setSelectedAttributes([]);
      setPayloadTierConfig({});
      setPayloadAttributes([]);
    };
  }, []);

  return (
    <div>
      <div>
        <div className="flex items-center">
          <div className="sm:flex-auto">
            <Text size="lg" className="flex items-center">
              <span>{editMode ? "Update" : "Create new"} Ideal Customer Profile (ICP)</span>
            </Text>
            <Text size="p" className="mt-1">
              ICP score will be shown for a company when prospecting. The ICP with the highest score will be
              shown as matched
            </Text>
          </div>
          <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
            <Button
              type="button"
              variant="plain"
              onClick={() => {
                setSelectedAttributes([]);
                formik.resetForm();
                setPayloadAttributes([]);
                if (onCancel) {
                  onCancel();
                }
              }}
            >
              Cancel
            </Button>
            {editMode ? (
              <Button
                type="button"
                disabled={
                  !formik.values.name ||
                  !formik.values.description ||
                  payloadAttributes.length === 0 ||
                  updateICPFn.isLoading
                    ? true
                    : false
                }
                onClick={clickUpdate}
              >
                Update {updateICPFn.isLoading && <div className="inline-block loader_circle ml-2"></div>}
              </Button>
            ) : (
              <Button
                type="button"
                disabled={
                  !formik.values.name ||
                  !formik.values.description ||
                  payloadAttributes.length === 0 ||
                  createICPFn.isLoading
                    ? true
                    : false
                }
                onClick={clickSave}
              >
                Save {createICPFn.isLoading && <div className="inline-block loader_circle ml-2"></div>}
              </Button>
            )}
          </div>
        </div>
        <div className="border-b border-border my-5"></div>

        <div className="flex space-x-6">
          <div className="w-6/12">
            <form onSubmit={formik.handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-2">
                  ICP Name
                </label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name && (
                  <p className="mt-1 text-red-500 text-sm">{formik.errors.name}</p>
                )}
              </div>

              <div>
                <label htmlFor="description" className="block text-sm font-medium mb-2">
                  Description
                </label>
                <textarea
                  id="description"
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                  className="px-4 py-3 text-sm w-full rounded-[--radius] border border-border bg-background text-foreground"
                  rows="1"
                />
                {formik.touched.description && formik.errors.description && (
                  <p className="mt-1 text-red-500 text-sm">{formik.errors.description}</p>
                )}
              </div>
            </form>
          </div>
          <div className="w-6/12">
            <div className="px-[14px] py-[15px] text-sm w-full rounded-[--radius] border border-input bg-background text-text-900 mt-7">
              <Text size="md" className="flex items-center">
                <span>How scoring works</span>
              </Text>
              <Text size="p" className="mt-2 mb-2">
                Each rule can have a score of -10–10 points.We calculate a raw score (ex: 17/50 pts = 0.34)
                for each record and assign a percentile ranking relative to other records. You can set the
                percentile ranges for the score labels (ex: “Excellent”) under Score distribution.{" "}
                {/* <a href="" className="text-brand-700">
                  Learn more.
                </a> */}
              </Text>
            </div>
          </div>
        </div>
        <div className="sm:flex-auto mt-9">
          <Text size="md" className="flex items-center">
            <span>Score Ranges</span>
          </Text>
          <Text size="p" className="mt-1">
            Score ranges determine how a score
          </Text>
        </div>
        <TierSlider
          getConfig={(data) => {
            setPayloadTierConfig(data);
          }}
          defaultValue={currentIcp?.scoring_tier_config}
        />
        <div className="sm:flex-auto mt-5">
          <Text size="md" className="flex items-center">
            <span>Company Attributes</span>
          </Text>
          <Text size="p" className="mt-1">
            ICP score will be shown for a company when prospecting. The ICP with the highest score will be
            shown as matched
          </Text>
        </div>
        {selectedAttributes.length === 0 && (
          <div className="max-w-[440px] text-center mt-10 mx-auto">
            <Image
              src="/images/icons/untitledui/icpattr.svg"
              className="m-auto no-invert bg-gray-25 dark:bg-white rounded-lg"
            />
            <p className="my-5">
              Click add rule to configure a new rule to match and score against. You can add as many rules as
              you like.
            </p>
            <Button onClick={openModal}>
              <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
              Add rule
            </Button>
          </div>
        )}
        {selectedAttributes.length > 0 && (
          <div className="mt-6">
            {selectedAttributes.map((attr) => (
              <AttributeCard
                attr={attr}
                removeAttribute={(id) => {
                  setSelectedAttributes((prev) => prev.filter((el) => el.id !== id));
                  setTimeout(() => {
                    removePayloadAttr(id);
                  }, 500);
                }}
                getAttrPayload={(attrData) => {
                  console.log("getAttrPayload AttributeCard", attrData);
                  updatePayloadAttributes(attr.id, attrData);
                }}
              />
            ))}
            <div className="text-center mt-5">
              <Button onClick={openModal}>
                <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Add rule
              </Button>
            </div>
          </div>
        )}
      </div>
      <GenericModal
        isOpen={isOpen}
        closeModal={() => {
          closeModal();
        }}
        width="w-[950px]"
      >
        <Attributes
          closeModal={closeModal}
          attributes={attributes}
          getAttributes={(arr) => {
            setSelectedAttributes(arr);
            setPayloadAttributes((prev) => prev.filter((item) => arr.some((obj) => obj.id === item.id)));
          }}
          activeAttributes={selectedAttributes}
        />
      </GenericModal>
    </div>
  );
}
