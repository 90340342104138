import React, { useEffect, useState } from "react";
import useRoleCheck from "../../../hooks/useRoleCheck";
import { paginationConfig } from "../../../axios/constant";
import { deleteIcp, getICPById, getICPs, patchICP } from "../../../apis/workspace/general";
import { isEmptyObject, returnArray } from "../../../utils/GenericFn";
import useUpdateQuery from "../../../hooks/useUpdateQuery";
import { useMutation } from "react-query";
import { Button } from "../../ui/button";
import Text from "../../Tags/Text";

import Image from "../../Tags/Image";
import { PlusIcon } from "lucide-react";

import PrintDate from "../../Tags/PrintDate";
import { Tooltip } from "../../ui/tooltip";
import { Switch } from "../../ui/switch";
import TableLoader from "../../Loaders/TableLoader";
import NoData from "../../NoData";
import Pagination from "../../Pagination";
import ConfirmModal from "../../Modals/ConfirmModal";
import CreateScore from "./CreateScore";
import { useSearchParams } from "react-router-dom";

export default function Scoring() {
  const [showCreateICP, setShowCreateICP] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentIcp, setCurrentIcp] = useState({});
  const { activeWorkspace } = useRoleCheck();
  const [apiResponse, setApiResponse] = useState({});
  const [icps, setIcps] = useState([]);
  const [filterQuery, setFilterQuery] = useState("");
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(paginationConfig);
  const [sort, setSort] = useState("");

  const getICPsFn = useMutation("get_icps", getICPs, {
    onSuccess(data, variables, context) {
      setApiResponse(data);
      setIcps(data?.data ? data?.data : []);
    },
  });

  const handleGetICPs = () => {
    getICPsFn.mutate({
      query: filterQuery,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  useEffect(() => {
    setFilterQuery(generateQueryString());
  }, [pagination, filters, sort]);

  useEffect(() => {
    if (activeWorkspace?.id && filterQuery !== "") {
      handleGetICPs();
    }
  }, [activeWorkspace, filterQuery]);

  const generateQueryString = (forApi = true) => {
    let params = [];
    if (forApi) {
      params = [`page=${pagination.page}`, `per_page=${pagination.perPage}`, `sort=${sort}`];
    }
    for (let key in filters) {
      let item = filters[key];
      if (item.selectedScope && item.value) {
        let parsedValue = item.value
          .split(",")
          .map((v) => v.trim())
          .join("|");
        params.push(`filter[${item.selectedScope}]=${parsedValue}`);
      }
    }

    return params.join("&");
  };

  const getFiltersObject = (data) => {
    if (isEmptyObject(data)) {
      setSort("");
    } else {
      setPagination(paginationConfig);
    }
    setFilters(data);
  };
  const getPageNumber = (page) => {
    setPagination((prev) => ({ ...prev, page }));
  };

  const handleSetSort = (column) => {
    if (sort === `-${column}`) {
      setSort(column);
    } else if (sort === column) {
      setSort(`-${column}`);
    } else {
      setSort(column);
    }
  };
  const [columns, setColumns] = useState({});
  const [sortedVisibleColumns, setSortedVisibleColumns] = useState(null);

  const getColumns = (columns) => {
    setColumns(columns);
    const visibleColumns = Object.values(columns).filter((column) => column.visible);
    const sortedVisibleColumns = visibleColumns.sort((a, b) => a.order - b.order);
    setSortedVisibleColumns(sortedVisibleColumns);
  };

  const setQueryString = useUpdateQuery();
  useEffect(() => {
    if (!isEmptyObject(filters)) {
      setQueryString(generateQueryString(false));
    }
  }, [filters]);

  // add delete icp
  const deleteIcpFn = useMutation("delete_icp", deleteIcp, {
    onSuccess(data, variables, context) {
      handleGetICPs();
    },
    onError(error) {
      console.log(error);
    },
  });

  const handleDeleteICP = () => {
    deleteIcpFn.mutate({
      id: currentIcpId,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  // handle open confirm
  const [currentIcpId, setCurrentIcpId] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModalType, setOpenConfirmModalType] = useState("");
  const confirmModalState = (data) => {
    setOpenConfirmModal(data.false);
    if (data && data.confirm && data.type && data.type === "delete") {
      handleDeleteICP();
    }
  };

  // get icp by id
  const getICPByIdFn = useMutation("get_icp_by_id", getICPById, {
    onSuccess(data, variables, context) {
      setCurrentIcp(data?.data);
    },
  });

  const handleGetAttributeById = (id) => {
    if (!activeWorkspace?.id) {
      return;
    }
    if (getICPByIdFn.isLoading) {
      return;
    }
    getICPByIdFn.mutate({
      id: id,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const mode = searchParams.get("mode");
    if (mode === "create") {
      setShowCreateICP(true);
      setEditMode(false);
      setCurrentIcp({});
      setCurrentIcpId("");
    }
    if (mode === "edit") {
      const icp_id = searchParams.get("icp_id");
      setEditMode(true);
      setCurrentIcpId(icp_id);
      handleGetAttributeById(icp_id);
    }
  }, [activeWorkspace?.id]);

  useEffect(() => {
    const handleRouteReset = () => {
      setShowCreateICP(false);
      setEditMode(false);
      setCurrentIcp({});
      setCurrentIcpId("");
      searchParams.delete("mode");
      searchParams.delete("icp_id");
      setSearchParams(searchParams);
      if (activeWorkspace?.id) {
        handleGetICPs();
      }
    };

    window.addEventListener("clearScoringRouteState", handleRouteReset);

    return () => {
      window.removeEventListener("clearScoringRouteState", handleRouteReset);
    };
  }, [activeWorkspace?.id]);

  // patch api
  const patchICPFn = useMutation("get_icp_by_id", patchICP, {
    onSuccess(data, variables, context) {
      console.log("data", data);
      handleGetICPs();
    },
  });
  const handlePatchICP = (data, id) => {
    if (patchICPFn.isLoading) {
      return;
    }
    patchICPFn.mutate({
      id: id,
      data: data,
      options: {
        headers: { "X-Workspace-Id": activeWorkspace?.id },
      },
    });
  };

  return (
    <>
      {!showCreateICP && !editMode && (
        <div>
          <div className="flex items-center">
            <div className="sm:flex-auto">
              <Text size="lg" className="flex items-center">
                <span>Company ICP</span>
              </Text>
              <Text size="p" className="mt-1">
                Understand fitment of company to your business and prioritize the best fit company.
              </Text>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Button
                type="button"
                onClick={() => {
                  setShowCreateICP(true);
                  setEditMode(false);
                  setCurrentIcp({});
                  setCurrentIcpId("");
                  setSearchParams({ mode: "create" });
                }}
              >
                <PlusIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                Create new
              </Button>
            </div>
          </div>
          {/* {apiResponse && apiResponse.data && (apiResponse.data.length > 0 || !isEmptyObject(filters)) && (
        <div className="mt-7 flex justify-between items-center">
          <div className="space-x-3 inline-flex items-center">
            {apiResponse && apiResponse.filterMetadata && (
              <>
                <span>Filters:</span>
                <Filter filterMetadata={apiResponse.filterMetadata} getFiltersObject={getFiltersObject} />
              </>
            )}
          </div>
          <div className="space-x-3">
            {apiResponse.columnMetadata && (
              <EditColumn columnMetadata={apiResponse.columnMetadata} getColumns={getColumns} />
            )}
          </div>
        </div>
      )}
      {apiResponse && apiResponse.data && apiResponse.data.length > 0 && getICPsFn.isSuccess && (
        <div className="mt-8 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden border border-border rounded-[--radius]">
                <table className="min-w-full divide-y divide-border">
                  <thead className="bg-background">
                    <tr>
                      {returnArray(sortedVisibleColumns).map((column, index) => (
                        <th
                          key={index}
                          scope="col"
                          className={`px-3 py-3.5 text-left text-sm font-normal   ${
                            column.sortable ? "cursor-pointer" : ""
                          }`}
                          onClick={() => (column.sortable ? handleSetSort(column.key) : void 0)}
                        >
                          <span className={"inline-flex items-center"}>
                            {column.display_name}
                            {column.sortable && (
                              <>
                                {sort === "" || (sort !== column.key && sort !== `-${column.key}`) ? (
                                  <Image
                                    src="/images/icons/dual-arrow.svg"
                                    width="7"
                                    height="7"
                                    className="inline-block ml-1"
                                  />
                                ) : (
                                  <>
                                    {sort === column.key && (
                                      <ArrowDownIcon width={12} className="inline-block ml-1" />
                                    )}
                                    {sort === `-${column.key}` && (
                                      <ArrowUpIcon width={12} className="inline-block ml-1" />
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </span>
                        </th>
                      ))}
                      <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-border bg-background">
                    {icps &&
                      returnArray(icps).map((icp, index) => (
                        <tr key={icp.id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                          {returnArray(sortedVisibleColumns).map((column, colIndex) => {
                            const colKey =
                              columns &&
                              Object.keys(columns).find((key) => columns[key].order === column.order);

                            if (colKey === "member_since") {
                              return (
                                <td
                                  key={colIndex}
                                  className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                >
                                  <PrintDate date={icp?.created_at} />
                                </td>
                              );
                            }
                            if (colKey === "last_used_at") {
                              return (
                                <td
                                  key={colIndex}
                                  className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                                >
                                  {icp?.last_used_at ? <PrintDate date={icp?.last_used_at} /> : "-"}
                                </td>
                              );
                            }

                            return (
                              <td
                                key={colIndex}
                                className="whitespace-nowrap px-3 py-4 text-sm text-paragraph"
                              >
                                {icp[colKey] ? icp[colKey] : "-"}
                              </td>
                            );
                          })}
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                            <div className="flex justify-end w-full">
                              <Button
                                data-tooltip-id="in_table_actions"
                                data-tooltip-content="Edit"
                                variant="ghost"
                                // onClick={() => {
                                //   setEdit(true);
                                //   setCurrentKey(key);
                                //   openModal();
                                //   formik.setFieldValue("name", key.name);
                                // }}
                              >
                                <Image
                                  src="/images/icons/untitledui/edit.svg"
                                  width="16"
                                  height="16"
                                  className="inline-block"
                                />
                              </Button>

                              <Button
                                data-tooltip-id="in_table_actions"
                                data-tooltip-content="Delete"
                                variant="ghost"
                                // onClick={() => {
                                //   setCurrentKeyId(key?.id);
                                //   setOpenConfirmModal(true);
                                //   setOpenConfirmModalType("delete");
                                // }}
                              >
                                <Image
                                  src="/images/icons/untitledui/trash.svg"
                                  width="16"
                                  height="16"
                                  className="inline-block"
                                />
                              </Button>
                              <Tooltip id="in_table_actions" />
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )} */}

          {getICPsFn.isSuccess && icps && icps.length === 0 && <NoData heading={"No ICPs found!"} />}

          {icps && icps.length > 0 && (
            <div className="mt-8 flow-root">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="overflow-hidden border border-border rounded-[--radius]">
                    <table className="min-w-full divide-y divide-border">
                      <thead className="bg-background">
                        <tr>
                          <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                            <span className={"inline-flex items-center"}>Name</span>
                          </th>
                          <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                            <span className={"inline-flex items-center"}>Description</span>
                          </th>
                          <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                            <span className={"inline-flex items-center"}>Created At</span>
                          </th>
                          <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                            <span className={"inline-flex items-center"}>Updated At</span>
                          </th>
                          <th scope="col" className={`px-3 py-3.5 text-left text-sm font-normal`}>
                            <span className={"inline-flex items-center"}>Status</span>
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold "></th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-border bg-background">
                        {icps &&
                          returnArray(icps).map((icp, index) => (
                            <tr key={icp.id} className={index % 2 !== 0 ? "bg-smoke" : ""}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">
                                {icp?.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">
                                {icp?.description}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">
                                <PrintDate date={icp?.created_at} />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">
                                <PrintDate date={icp?.updated_at} />
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-paragraph">
                                <div className="inline-flex items-center w-[70px]">
                                  <Switch
                                    checked={icp?.is_active}
                                    onCheckedChange={(checked) => {
                                      setCurrentIcpId(icp.id);
                                      handlePatchICP({ is_active: !icp?.is_active }, icp.id);
                                    }}
                                    className="mr-2"
                                  />
                                  {patchICPFn.isLoading && icp.id === currentIcpId && (
                                    <div className="loader loader_circle inline-block"></div>
                                  )}
                                </div>
                              </td>

                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <div className="flex justify-end w-full min-w-[150px]">
                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Edit"
                                    variant="ghost"
                                    onClick={() => {
                                      setEditMode(true);
                                      setCurrentIcpId(icp.id);
                                      handleGetAttributeById(icp.id);
                                      setSearchParams({ mode: "edit", icp_id: icp.id });
                                    }}
                                  >
                                    <Image
                                      src="/images/icons/untitledui/edit.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                  </Button>

                                  <Button
                                    data-tooltip-id="in_table_actions"
                                    data-tooltip-content="Delete"
                                    variant="ghost"
                                    onClick={() => {
                                      setCurrentIcpId(icp?.id);
                                      setOpenConfirmModal(true);
                                      setOpenConfirmModalType("delete");
                                    }}
                                  >
                                    <Image
                                      src="/images/icons/untitledui/trash.svg"
                                      width="16"
                                      height="16"
                                      className="inline-block"
                                    />
                                  </Button>
                                  <Tooltip id="in_table_actions" />
                                </div>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
          {getICPsFn.isLoading && !currentIcpId && <TableLoader />}
          {getICPsFn.isSuccess &&
            apiResponse &&
            apiResponse.data &&
            apiResponse.data.length === 0 &&
            isEmptyObject(filters) && <NoData heading={"No ICPs found!"} />}
          {getICPsFn.isSuccess &&
            apiResponse &&
            apiResponse.data &&
            apiResponse.data.length === 0 &&
            !isEmptyObject(filters) && (
              <div className="p-10 border rounded-lg mt-8">
                <NoData heading={"No ICPs found!"} filterMode={true} />
              </div>
            )}
          <div className="flex items-center justify-between  rounded-[--radius] py-3">
            <div className="flex-1">
              {apiResponse && apiResponse.pagination && apiResponse.pagination.total > 0 && (
                <Pagination data={apiResponse.pagination} getPageNumber={getPageNumber} />
              )}
            </div>
          </div>
        </div>
      )}

      {getICPByIdFn.isLoading && (
        <div className="justify-center items-center mt-[40vh]">
          <div className="flex justify-center flex-col items-center">
            <div className="loader_circle loader_loading"></div>
            <p className="text-base font-semibold text-gray-500 mt-3">Loading</p>
          </div>
        </div>
      )}

      {(showCreateICP || (editMode && currentIcp.id)) && (
        <CreateScore
          editMode={editMode}
          currentIcp={currentIcp}
          onCreated={() => {
            setShowCreateICP(false);
            handleGetICPs();
            searchParams.delete("mode");
            searchParams.delete("icp_id");
            setSearchParams(searchParams);
          }}
          onUpdated={() => {
            setEditMode(false);
            setCurrentIcp({});
            setCurrentIcpId("");
            handleGetICPs();
            searchParams.delete("mode");
            searchParams.delete("icp_id");
            setSearchParams(searchParams);
          }}
          onCancel={() => {
            setShowCreateICP(false);
            setEditMode(false);
            setCurrentIcp({});
            setCurrentIcpId("");
            searchParams.delete("mode");
            searchParams.delete("icp_id");
            setSearchParams(searchParams);
          }}
        />
      )}

      <ConfirmModal
        openModal={openConfirmModal}
        modalType={openConfirmModalType}
        confirmModalState={confirmModalState}
        title={"Are you sure you want to delete your ICP?"}
      />
    </>
  );
}
