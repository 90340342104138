import React, { useState, useEffect } from "react";
import Input from "../../Tags/Input";

const DynamicDateConditionInput = ({ date1, date2, options, selected, onChange }) => {
  const [input, setInput] = useState(date1); // date or number
  const [input2, setInput2] = useState(date2); // for range

  useEffect(() => {
    if (!selected) return;

    const result = (() => {
      if (["inRange", "notInRange"].includes(selected.value)) {
        if (input && input2) {
          return { operator: selected.value, value: [input, input2] };
        }
      } else if (selected.value.includes("withinLast")) {
        if (input) {
          return { operator: selected.value, value: Number(input) };
        }
      } else {
        if (input) {
          return { operator: selected.value, value: input };
        }
      }
      return null;
    })();

    if (result) {
      onChange(result);
    }
  }, [input, input2, selected]);

  const renderInputFields = () => {
    if (!selected) return null;

    if (["on", "before", "after"].includes(selected.value)) {
      return <Input type="date" value={input} onChange={(e) => setInput(e.target.value)} />;
    }

    if (["inRange", "notInRange"].includes(selected.value)) {
      return (
        <div className="flex gap-2 mt-2">
          <Input type="date" value={input} onChange={(e) => setInput(e.target.value)} />
          <span className="mt-2">to</span>
          <Input type="date" value={input2} onChange={(e) => setInput2(e.target.value)} />
        </div>
      );
    }

    if (selected.value.includes("withinLast") || selected.value.includes("notWithinLast")) {
      return (
        <Input
          type="text"
          placeholder="Enter number"
          value={input}
          onChange={(e) => {
            const val = e.target.value;
            if (/^\d*$/.test(val)) {
              setInput(val);
            }
          }}
        />
      );
    }

    return null;
  };

  return (
    <div className="flex flex-col mt-3">
      {/* <label className="text-sm font-medium text-gray-700 mb-1">{selected?.name}</label> */}
      {renderInputFields()}
    </div>
  );
};

export default DynamicDateConditionInput;
