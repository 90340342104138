import React, { useState, useEffect } from "react";
import Input from "../../Tags/Input";

const DualNumberInput = ({
  currentValue = "",
  currentMaxValue = "",
  showMaxInput = true,
  onChange,
  validation, // { min, max } optional
}) => {
  console.log("validation", validation);
  const [value, setValue] = useState(currentValue);
  const [maxValue, setMaxValue] = useState(currentMaxValue);
  const [errors, setErrors] = useState({ value: "", maxValue: "" });

  const handleChange = (e, setter, field) => {
    const input = e.target.value;

    // ✅ Allow empty string and valid numbers (no leading 0s except 0, optional minus)
    const regex = /^-?(0|[1-9]\d*)?$/;

    if (input === "" || (regex.test(input) && input !== "-0" && input !== "-" && !input.includes("e"))) {
      const number = Number(input);

      if (validation && input !== "") {
        const { max } = validation;

        if (max !== null && max !== undefined && number > max) return;
      }

      setter(input);
      setErrors((prev) => ({ ...prev, [field]: "" }));
    }
  };

  useEffect(() => {
    const parsedValue = value === "" ? undefined : Number(value);
    const parsedMax = maxValue === "" ? undefined : Number(maxValue);

    if (showMaxInput) {
      onChange({
        min_value: parsedValue,
        max_value: parsedMax,
      });
    } else {
      onChange({
        value: parsedValue,
      });
    }
  }, [value, maxValue, showMaxInput]);

  return (
    <div className="flex items-center gap-4 mt-3">
      <div className="flex-1">
        <Input
          type="text"
          value={value}
          onChange={(e) => handleChange(e, setValue, "value")}
          placeholder="Enter value"
        />
        {errors.value && <p className="text-xs text-red-500 mt-1">{errors.value}</p>}
      </div>

      {showMaxInput && (
        <>
          <span>-</span>
          <div className="flex-1">
            <Input
              type="text"
              value={maxValue}
              onChange={(e) => handleChange(e, setMaxValue, "maxValue")}
              placeholder="Enter max value"
            />
            {errors.maxValue && <p className="text-xs text-red-500 mt-1">{errors.maxValue}</p>}
          </div>
        </>
      )}
    </div>
  );
};

export default DualNumberInput;
